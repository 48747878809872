.uikit-tooltip-content {
  background-color: white;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
  word-break: break-word;

  &.uikit-tooltip-size-sm {
    padding: 6px;
  }

  &.uikit-tooltip-size-md {
    padding: 8px;
  }

  &.uikit-tooltip-size-lg {
    padding: 16px;
  }

  &::before {
    content: ' ';
    border: 10px solid transparent;
    position: absolute;
    pointer-events: none;
  }

  &.uikit-tooltip-down-left::before {
    border-bottom-color: white;
    right: 20px;
    top: -20px;
  }

  &.uikit-tooltip-down-right::before {
    border-bottom-color: white;
    left: 20px;
    top: -20px;
  }

  &.uikit-tooltip-up-left::before {
    border-top-color: white;
    right: 20px;
    bottom: -20px;
  }

  &.uikit-tooltip-up-right::before {
    border-top-color: white;
    left: 20px;
    bottom: -20px;
  }

  &.uikit-tooltip-right-down::before {
    border-right-color: white;
    left: -20px;
    top: 20px;
  }

  &.uikit-tooltip-left-down::before {
    border-left-color: white;
    right: -20px;
    top: 20px;
  }

  &.uikit-tooltip-right-up::before {
    border-right-color: white;
    left: -20px;
    bottom: 20px;
  }

  &.uikit-tooltip-left-up::before {
    border-left-color: white;
    right: -20px;
    bottom: 20px;
  }

  &.uikit-tooltip-center-up::before {
    border-top-color: white;
    bottom: -20px;
    left: calc(50% - 10px);
  }

  &.uikit-tooltip-center-down::before {
    border-bottom-color: white;
    top: -20px;
    left: calc(50% - 10px);
  }
}
