@use 'sass:math';
@import 'helpers/_parameters';
@import 'ui-kit/styles/palette';

//#region global

.warning-text {
  color: $color-bad-dark;
}

//#endregion

.uikit-tooltip-content.uikit-tooltip-size-sm {
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.uikit-tooltip-content.uikit-tooltip-size-md {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.uikit-tooltip-content.uikit-tooltip-size-lg {
  font-size: 0.875rem;
  line-height: 1.375rem;

  //
  // Headings
  //

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    line-height: 0.875rem;
  }

  h1 {
    font-size: 2.125rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  h5 {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  > p:last-child {
    margin-bottom: 0;
  }

  dd {
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-bottom: 0.875rem;
  }

  > dl:last-child > dd:last-child {
    margin-bottom: 0;
  }

  @media #{$breakpoint-web} {
    dt {
      font-size: 1rem;
      line-height: 1.375rem;
      margin-bottom: 0.375rem;
    }

    dd {
      font-size: 0.875rem;
      line-height: 1.375rem;
      margin-bottom: 0.5rem;
    }

    > dl:last-child > dd:last-child {
      margin-bottom: 0;
    }
  }

  //
  // Horizontal rules
  //

  hr {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
}
